import React from 'react';
import './MainPage.css';
import croatia from './resources/croatia.webp';
import homolka from './resources/homolka.webp';
import interview_build from './resources/interview_build.webp';
import interview_office from './resources/interview_office.webp';
import kami from './resources/kami.webp';
import muchovo from './resources/muchovo.webp';
import sprievod from './resources/sprievod.webp';
import aftermovie from './resources/aftermovie.webp';
import {Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, useDisclosure, Popover, PopoverTrigger, PopoverContent, Button, Divider} from "@nextui-org/react";
import Thumbnail from "./components/Thumbnail";
import logo_f from './resources/logo_only_f.svg';
import {useState} from "react";
import { useTranslation } from 'react-i18next';
import logoFBlack from './resources/logo_only_f_black.svg';
import logoIg from './resources/ig-logo.svg';
import logoLinkedIn from './resources/linkedin-logo.svg';
import logoPhone from './resources/phone-logo.svg';
import logoMail from './resources/mail-logo.svg';
import ScrollIndicator from './components/ScrollIndicator';

const MainPage = ({ sectionRef, animate, onAnimationEnd }) => {

    const {isOpen, onOpen, onOpenChange} = useDisclosure();

    const [videoUrl, setVideoUrl] = useState('');
    const [videoTitle, setVideoTitle] = useState('');

    const openVideo = (url, title) => {
        setVideoUrl(url);
        setVideoTitle(title);
        onOpen();
    }

    const { t } = useTranslation();

    const chorvatskoUrl = "https://player.vimeo.com/video/991234377?h=1ae96db329&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&autoplay=1";
    const homolkaUrl = "https://player.vimeo.com/video/962669961?h=0bf6231ca8&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&autoplay=1";
    const interviewBuildUrl = "https://player.vimeo.com/video/991186462?h=21a7039c14&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&autoplay=1";
    const interviewOfficeUrl = "https://player.vimeo.com/video/991184241?h=737d0ee6a6&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&autoplay=1";
    const kamiUrl = "https://player.vimeo.com/video/950542712?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&autoplay=1";
    const muchovoUrl = "https://player.vimeo.com/video/991239803?h=cf8c0edf17&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&autoplay=1";
    const sprievodUrl = "https://player.vimeo.com/video/991194952?h=c35b387e26&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&autoplay=1";
    const aftermovieUrl = "https://player.vimeo.com/video/991449268?h=9df19b9d7d&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&autoplay=1";

    const chorvatskoTitle = t('chorvatskoTitle');
    const homolkaTitle = t('homolkaTitle');
    const interviewBuildTitle = t('interviewBuildTitle');
    const interviewOfficeTitle = t('interviewOfficeTitle');
    const kamiTitle = t('kamiTitle');
    const muchovoTitle = t('muchovoTitle');
    const sprievodTitle = t('sprievodTitle');
    const aftermovieTitle = t('aftermovieTitle');
    

    return (
        <div>
            <ScrollIndicator/>
            <div className='intro-columns'>
                    <h1>{t('first_line')}</h1>
                    <div className='intro-rows'>
                    <div className='intro-row'>
                        <p className='intro-row-left'>{t('table_row1L')}</p>
                        <p className='intro-row-right'>{t('table_row1R')}</p>
                    </div>
                    <Divider className="divider" />
                    <div className='intro-row'>
                        <p className='intro-row-left'>{t('table_row2L')}</p>
                        <p className='intro-row-right'>{t('table_row2R')}</p>
                    </div>
                    <Divider className="divider"/>
                    <div className='intro-row'>
                        <p className='intro-row-left'>{t('table_row3L')}</p>
                        <p className='intro-row-right'>{t('table_row3R')}</p>
                    </div>
                    <Divider className="divider" />
                </div> 
            </div>
            
            <div className="grid-container">
                <div className="grid-row-2">
                    <div className="grid-item">
                        <Thumbnail
                            title={homolkaTitle}
                            image={homolka}
                            description={t('homolka_description')}
                            isSmall={true}
                            onButtonClick={() => openVideo(homolkaUrl, homolkaTitle)}
                            differentAngle={true}
                        />
                    </div>
                    <div className="grid-item">
                        <Thumbnail
                            title={muchovoTitle}
                            image={muchovo}
                            description={t('muchovo_description')}
                            isSmall={true}
                            onButtonClick={() => openVideo(muchovoUrl, muchovoTitle)}
                        />
                    </div>
                </div>
                <div className="grid-row-1">
                    <Thumbnail
                        title={interviewOfficeTitle}
                        image={interview_office}
                        description={t('interview_office_description')}
                        isSmall={false}
                        onButtonClick={() => openVideo(interviewOfficeUrl, interviewOfficeTitle)}
                    />
                </div>
                <div className="grid-row-2">
                    <div className="grid-item">
                        <Thumbnail
                            title={sprievodTitle}
                            image={sprievod}
                            description={t('sprievod_description')}
                            isSmall={true}
                            onButtonClick={() => openVideo(sprievodUrl, sprievodTitle)}
                            differentAngle={true}
                        />
                    </div>
                    <div className="grid-item">
                        <Thumbnail
                        title={chorvatskoTitle}
                        image={croatia}
                        description={t('chorvatsko_description')}
                        isSmall={true}
                        onButtonClick={() => openVideo(chorvatskoUrl, chorvatskoTitle)}
                        />
                    </div>
                </div>
                <div className="grid-row-1">
                    <Thumbnail
                        title={kamiTitle}
                        image={kami}
                        description={t('kami_description')}
                        isSmall={false}
                        onButtonClick={() => openVideo(kamiUrl, kamiTitle)}
                    />
                </div>
                <div className="grid-row-2">
                    <div className="grid-item">
                        <Thumbnail
                            title={interviewBuildTitle}
                            image={interview_build}
                            description={t('interview_build_description')}
                            isSmall={true}
                            onButtonClick={() => openVideo(interviewBuildUrl, interviewBuildTitle)}
                            differentAngle={true}
                        />
                    </div>
                    <div className="grid-item">
                        <Thumbnail
                            title={aftermovieTitle}
                            image={aftermovie}
                            description={t('aftermovie_description')}
                            isSmall={true}
                            onButtonClick={() => openVideo(aftermovieUrl, aftermovieTitle)}
                        />
                    </div>
                
                </div>
            </div>
            <div className="column-container">
                <Thumbnail
                    title={homolkaTitle}
                    image={homolka}
                    description={t('homolka_description')}
                    isSmall={true}
                    onButtonClick={() => openVideo(homolkaUrl, homolkaTitle)}
                />
                <Thumbnail
                    title={muchovoTitle}
                    image={muchovo}
                    description={t('muchovo_description')}
                    isSmall={true}
                    onButtonClick={() => openVideo(muchovoUrl, muchovoTitle)}
                />
                <Thumbnail
                    title={interviewOfficeTitle}
                    image={interview_office}
                    description={t('interview_office_description')}
                    isSmall={true}
                    onButtonClick={() => openVideo(interviewOfficeUrl, interviewOfficeTitle)}
                />
                <Thumbnail
                    title={sprievodTitle}
                    image={sprievod}
                    description={t('sprievod_description')}
                    isSmall={true}
                    onButtonClick={() => openVideo(sprievodUrl, sprievodTitle)}
                />
                <Thumbnail
                    title={chorvatskoTitle}
                    image={croatia}
                    description={t('chorvatsko_description')}
                    isSmall={true}
                    onButtonClick={() => openVideo(chorvatskoUrl, chorvatskoTitle)}
                    
                />
                <Thumbnail
                    title={kamiTitle}
                    image={kami}
                    description={t('kami_description')}
                    isSmall={true}
                    onButtonClick={() => openVideo(kamiUrl, kamiTitle)}
                />
                <Thumbnail
                    title={interviewBuildTitle}
                    image={interview_build}
                    description={t('interview_build_description')}
                    isSmall={true}
                    onButtonClick={() => openVideo(interviewBuildUrl, interviewBuildTitle)}
                />
                <Thumbnail
                    title={aftermovieTitle}
                    image={aftermovie}
                    description={t('aftermovie_description')}
                    isSmall={true}
                    onButtonClick={() => openVideo(aftermovieUrl, aftermovieTitle)}
                />
            </div>
            <Divider className="divider"/>
            <h1 className='heading-bottom'>{t('intro_title')} </h1>
            <div className='intro'>
                {t('intro')}
            </div>
            <div className={`attention-target ${animate ? 'attention contact-div' : 'contact-div'}`} ref={sectionRef} onAnimationEnd={onAnimationEnd}>
                <p className='contact-heading ch-a'>{t('contact_heading')}</p>
                <p className='contact-heading ch-b'>{t('contact_subheading')}</p>
                <p className='contact-text'>{t('contact_paragraph')}</p>
                <div className="contact-logos">
                    <Button isIconOnly variant="light" size='lg' className="contact-logo" onClick={() => window.open("https://www.instagram.com/piedro99", "_blank", "noopener,noreferrer")}>
                        <img src={logoIg} alt="instagram-logo" />
                    </Button>
                    <Button isIconOnly variant="light" size='lg' className="contact-logo" onClick={() => window.open("https://www.linkedin.com/in/peter-husar", "_blank", "noopener,noreferrer")}>
                        <img src={logoLinkedIn} alt="linkedin-logo" />
                    </Button>
                    <Popover placement="bottom" showArrow={true} backdrop='blur' >
                        <PopoverTrigger>
                            <Button isIconOnly variant="light" size='lg' className="contact-logo">
                                <img src={logoPhone} alt="phone-logo" />
                            </Button>
                        </PopoverTrigger>
                        <PopoverContent>
                            <div className="px-1 py-2" >
                            <div className="text-small font-bold" style={{color: "black", fontSize: "1em", paddingBottom: "5px"}}>{t('phone')}</div>
                            <a href="tel:+436606217617" style={{color: "black", fontSize: "1.5em"}}>+436606217617</a>
                            </div>
                        </PopoverContent>
                    </Popover>
                    <Popover placement="bottom" showArrow={true} backdrop='blur' >
                        <PopoverTrigger>
                            <Button isIconOnly variant="light" size='lg' className="contact-logo">
                                <img src={logoMail} alt="mail-logo"/>
                            </Button>
                        </PopoverTrigger>
                        <PopoverContent>
                            <div className="px-1 py-2" >
                            <div className="text-small font-bold" style={{color: "black", fontSize: "1em", paddingBottom: "5px"}}>Email</div>
                            <a href="mailto:petohusar@gmail.com" style={{color: "black", fontSize: "1.5em"}}>petohusar@gmail.com</a>
                            </div>
                        </PopoverContent>
                    </Popover>
                </div>
                <img src={logoFBlack} alt="fulminare-logo" className="centered-logo"/>
            </div>
            <Modal isOpen={isOpen} onOpenChange={onOpenChange} backdrop='blur' size='5xl' className="custom-modal-container" placement='center'>
                <ModalContent className="custom-modal">
                {(onClose) => (
                    <>
                    <ModalHeader className="flex flex-col gap-1" style={{"fontSize": "2em", paddingTop: "20px", paddingBottom: "20px"}}>{videoTitle}</ModalHeader>
                    <ModalBody>
                        <div style={{"padding":"56.25% 0 0 0", "position":"relative"}}>
                            <iframe src={videoUrl} frameborder="0" allow="autoplay; fullscreen;" style={{"position":"absolute","top":0,"left":0,"width":"100%","height":"100%"}} title={videoTitle}>
                            </iframe>
                        </div>
                        <script src="https://player.vimeo.com/api/player.js">
                        </script>
                    </ModalBody>
                    <ModalFooter style={{"display": "flex", "flexDirection": "row", "justifyContent": "center"}}>
                            <img src={logo_f} alt="fulminare-logo" style={{"maxWidth": "40px"}}/>
                    </ModalFooter>
                    </>
                )}
                </ModalContent>
            </Modal>
        </div>
    );
}

export default MainPage;