import {Button} from "@nextui-org/react";
import React, { useCallback, useEffect } from "react";
import logo from '../resources/logo_f.svg';
import './NavbarF.css';
import { useTranslation } from 'react-i18next';
import { Select, SelectItem } from '@nextui-org/react';
import { useState } from 'react';


const NavbarF = ({ scrollToSection }) => {
    
    const { i18n, t } = useTranslation();

    const [value, setValue] = useState(new Set(['en']));

    const changeLanguage = useCallback((lng) => {
        i18n.changeLanguage(lng);
    }, [i18n]);
    
    useEffect(() => {
        value.forEach((v) => {
            changeLanguage(v);
        });
    }, [value, changeLanguage]);   

    return (
        <nav>
            <div className="navbar">
                <img src={logo} alt="fulminare-logo"/>
                <div className="navbar-end">
                    
                        <Button className="button-nav" style={{ backgroundColor: '#EFFF04', color: 'black', padding: '10px 20px', width: 'auto' }} onClick={scrollToSection}>{t('contact_us')}</Button>
                        <Select
                            aria-label="Select Language"
                            variant="bordered"
                            selectedKeys={value}
                            size="md"
                            style={{width: "70px"}}
                            onSelectionChange={setValue}
                        >
                            <SelectItem key="en" value={"en"}>
                            🇬🇧
                            </SelectItem>
                            <SelectItem key="sk" value={"sk"}>
                            🇸🇰
                            </SelectItem>
                        </Select>
                </div>
            </div>
        </nav>
    );
};

export default NavbarF;


