import './App.css';
import { NextUIProvider } from '@nextui-org/react';
import NavbarF from './components/NavbarF';
import MainPage from './MainPage';
import Footer from './components/Footer';
import './i18n';
import { useTranslation } from 'react-i18next';
import { Suspense, useEffect, useRef, useState } from 'react';
import AOS from 'aos';

function App() {

  const { t, i18n } = useTranslation();

  const [animate, setAnimate] = useState(false);

  const sectionRef = useRef(null);

  AOS.init({
    once: false,
    duration: 800,
    mirror: true
  });

  const scrollToSection = () => {
    sectionRef.current.scrollIntoView({ behavior: 'smooth' });
    setAnimate(true);
  };

  const handleAnimationEnd = () => {
    setAnimate(false);
  };

  useEffect(() => {
    document.title = t('page_title');
  }, [i18n.language, t]);


  return (
    <Suspense fallback='loading'>
      <NextUIProvider>
        <div className='wrapper'>
          <NavbarF scrollToSection={scrollToSection}/>
          <MainPage sectionRef={sectionRef} animate={animate} onAnimationEnd={handleAnimationEnd}/>
          <Footer/>
        </div>
      </NextUIProvider>
    </Suspense>
  );
}

export default App;
