import React from 'react';
import './Footer.css'; // Ensure you create this CSS file
import { useTranslation } from 'react-i18next';

const Footer = () => {

  const { t } = useTranslation();

  return (
    <footer className="footer">
      <p>{t('footer.copyright')}</p>
      <p className="powered-by">{t('footer.poweredBy')}</p>
    </footer>
  );
};

export default Footer;
