import React, { useState, useEffect } from 'react';
import './ScrollIndicator.css'; // Ensure this file is correctly linked

const ScrollIndicator = () => {
  const [hidden, setHidden] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 10) {
        setHidden(true);
      } else {
        setHidden(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={`scroll-indicator-overlay ${hidden ? 'hidden' : ''}`}>
      <div className="scroll-arrow">▼</div>
    </div>
  );
};

export default ScrollIndicator;
