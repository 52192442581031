import React from 'react';
import { Accordion, AccordionItem, Image, Button } from '@nextui-org/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import './Thumbnail.css'; 
import { useTranslation } from 'react-i18next';

const Thumbnail = ({ title, image, description, onButtonClick, isSmall, differentAngle }) => {

  const { t } = useTranslation();
  return (
    <div className="thumbnail-wrapper" data-aos={isSmall ? (differentAngle ? "fade-right" : "fade-left") : "fade-up"}>
      <div className={`image-container ${differentAngle ? "left" : ""}`}>
        <Image
          alt={title}
          shadow="full"
          src={image}
          className="image-test"
          loading="lazy"
        />
       <Button
          className="image-button"
          size={isSmall ? 'md' : 'lg'}
          radius='full'
          style={{ background: "linear-gradient(137deg, rgba(0,0,0,1) 33%, rgba(29,29,30,0.6938875786163522) 88%)", color: 'white', height: isSmall ? "40px" : "60px" }} 
          auto
          startContent={
            <div className="play-icon-container">
              <FontAwesomeIcon icon={faPlay} size="2x" />
            </div>}
          onPress={onButtonClick}
        >
          {t('play_video')}
          </Button>
      </div>
      <Accordion>
        <AccordionItem 
          aria-label={title} 
          isCompact
          title={<span style={{ color: 'white', fontFamily: 'DM Serif Display, serif', fontSize: isSmall ? "1.7em" : '2em'}}>{title}</span>}
          style={{ fontFamily: 'Inter, sans-serif'}}
          classNames={{
            indicator: 'custom-indicator-class',
            heading: isSmall  ? 'custom-heading-class-sm' : 'custom-heading-class',
          }}
        >
          {description}
        </AccordionItem>
      </Accordion>
    </div>
  );
}

export default Thumbnail;
